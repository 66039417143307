import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const save = (params) => {
  return ajax.post(`${base}/api/itedu/v1/homeworkAssignConfig`, params);
};

const detail = (id) => {
  return ajax.get(`${base}/api/itedu/v1/homeworkAssignConfig/${id}`);
};

const query = (search, start, len) => {
  return ajax.get(`${base}/api/itedu/v1/homeworkAssignConfig/query?search=${search}&start=${start}&len=${len}`);
};

const deleteItem = (id) => {
  return ajax.post(`${base}/api/itedu/v1/homeworkAssignConfig/delete?homeworkId=${id}`);
};

export const homeworkAssignConfigApi = {
  save,
  detail,
  query,
  deleteItem,
};
