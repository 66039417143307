<template>
  <div class="main">
    <el-form :label-position="'left'" label-width="80px" style="margin: 20px; width: 60%; min-width: 700px">
      <el-form-item label="id">
        <el-input v-model="homeWork.homeworkId" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="title">
        <el-input v-model="homeWork.title" placeholder="必填"></el-input>
      </el-form-item>
      <el-form-item label="courseId">
        <el-input v-model="homeWork.courseId" placeholder="必填"></el-input>
      </el-form-item>
      <el-form-item label="key">
        <el-input v-model="homeWork.key" placeholder="学科学段，列如：小学语文、高中数学、行测言语。"></el-input>
      </el-form-item>
      <el-form-item label="文件配置">
        <el-input
          class="teacher-text"
          type="textarea"
          placeholder="文件配置一行代表一篇课文。每一行的格式： 文件名#对象存储的key."
          v-model="homeWork.config"
          style="width: 100%;"
          :rows="10"
        ></el-input>
      </el-form-item>

      <el-row class="button-row">
        <el-form-item>
          <el-button type="primary" @click="onAddEdit">保存</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import msgLogger from "@/util/msgLogger";
import { homeworkAssignConfigApi } from "@/api/homeworkAssignConfigApi";
import dayjs from "dayjs";

export default {
  components: {},
  data() {
    return {
      user: null,
      course: null,
      homeWork: {},
      homeWorkId: 0,
    };
  },
  methods: {
    onAddEdit() {
      console.log("#", this.homeWork);
      homeworkAssignConfigApi
        .save(this.homeWork)
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0 && data) {
            this.homeWork = data;
            msgLogger.done("操作成功");
            return;
          }
        })
        .catch((err) => {
          msgLogger.error("系统出错" + err);
        });
    },

    initData(id) {
      homeworkAssignConfigApi.detail(id).then((ret) => {
        let { code, data } = ret;
        if (code == 0 && data) {
          this.homeWork = data;
          console.log("#1", this.homeWork);
        }
      });
    },
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.id) {
      const id = this.$route.query.id;
      this.homeWorkId = id;
      this.initData(id);
    }
  },
  filters: {
    timeStr: function(value) {
      let day = dayjs(Number(value));
      return day.format("YYYY-MM-DD hh:mm");
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .teacher-text {
    width: 80%;
  }
  .button-row {
    margin: 20px;
  }
}
</style>
